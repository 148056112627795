<template>
  <main class="index-page">
    <!-- <section>
      <div style="display: block; width: 100%; text-align: center;">
        <video style="display: inline-block; width: 300px; height: 300px;" muted="" loop="" disablepictureinpicture="" webkit-playsinline="" playsinline="" pip="false" autoplay="true">
          <source src="/static_files/wave.mp4" type="video/mp4">
        </video>
      </div>
    </section> -->
    
    <section>
      <div v-for="(index, type) in types" :key="`main-type-${type}-${index}`" class="slider">
        <h2>{{ lang(type, 'ru') }}</h2>
        <div class="wrapper">
          <div :style="`width:${types[type].length * 240}px`" class="items">
            <nuxt-link
              v-for="elem in types[type]"
              :key="`${type}-${elem.id}`"
              :to="`${type === 'radio' ? `/collection/radio` : `/collection/${type}/${elem.id}`}`"
              class="item"
            >
              <div class="poster">
                <img :src="elem.cover" alt="">
                <div class="overlay">
                  <div class="start"></div>
                </div>
              </div>
              <strong>{{ elem.title }}</strong>
            </nuxt-link>
          </div>
          <div class="actions">
            <div @click="sliderScroll($event, 'prev')" class="prev"></div>
            <div @click="sliderScroll($event, 'next')" class="next"></div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <h1>Слушай и скачивай музыку бесплатно на MuzZilka</h1>

      <div class="desc">
        <p><strong>MuzZilka</strong> - это идеальное место для всех меломанов, которые хотят наслаждаться новыми треками и <strong>скачивать любимую музыку абсолютно бесплатно</strong>. У нас вы найдете огромную коллекцию новинок Mp3 музыки, которые можно слушать онлайн или скачать на свое устройство.</p>
        <p>Наш сайт предлагает широкий выбор музыкальных жанров - от популярной и электронной музыки до рока, хип-хопа и джаза. Независимо от ваших музыкальных предпочтений, у нас есть что-то для каждого.</p>
        <p>Основное преимущество MuzZilka - это возможность <strong>слушать музыку онлайн без регистрации</strong>. Вы можете просматривать нашу коллекцию треков, состоящую из самых свежих релизов и хитов прошлых лет, и наслаждаться музыкой прямо на сайте. У нас также есть возможность скачать понравившиеся треки в формате Mp3, чтобы слушать их в оффлайн-режиме.</p>
        <p>Кроме того, наш сайт регулярно <strong>обновляется новыми треками</strong>, так что вы всегда будете в курсе последних музыкальных новинок. Вы можете следить за обновлениями через нашу рассылку или подписаться на наши социальные сети, чтобы не пропустить ни одного интересного трека.</p>
        <p>Сайт MuzZilka - это ваша музыкальная площадка, где вы можете наслаждаться любимыми треками в любое время. <strong>Слушайте и скачивайте новинки Mp3 музыки бесплатно</strong> - присоединяйтесь к нам прямо сейчас!</p>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  head() {
    return {
      title: `Слушай и скачивай музыку бесплатно: новинки Mp3 музыки - MuzZilka`,
    }
  },
  async asyncData({ $axios }) {
    const { data } = await $axios.get(`/api/index`)
    // console.log('data', data)
    return {
      types: data,
    }
  },
  methods: {
    sliderScroll(event, direction) {
      const sliderElem = event.target.parentElement.parentElement
      if (sliderElem) {
        const scrollPos = sliderElem.scrollLeft
        if (direction === 'prev') {
          sliderElem.scrollTo(scrollPos - 1000, 0)
        } else {
          sliderElem.scrollTo(scrollPos + 1000, 0)
        }
      }
    },
  },
}
</script>

<style lang="stylus">
.index-page
  section
    margin: 0px auto
  h2
    text-transform: capitalize
    margin-bottom: 10px
  h1
    margin-bottom: 40px
  .desc
    p
      margin: 15px 0px
      font-size: 1.2rem
      strong
        font-weight: 500
  .slider
    position: relative
    margin-bottom: 60px
    overflow: hidden
    .actions
      display: block
      position: absolute
      top: 0px
      left: 0px
      width: 100%
      height: 100%
      opacity: 0
      transition: opacity 0.2s ease-out
      .prev, .next
        display: inline-block
        position: absolute
        top: 150px
        width: 30px
        height: 30px
        background-color: #fff
        border-radius: 50%
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15)
        cursor: pointer
        opacity: 0.75
        z-index: 2
        &:hover
          opacity: 1
      .prev
        left: 10px
        background-image: url('/static_files/icons/chevron-left.svg')
        background-size: 14px
        background-repeat: no-repeat
        background-position: center
      .next
        right: 10px
        background-image: url('/static_files/icons/chevron-right.svg')
        background-size: 14px
        background-repeat: no-repeat
        background-position: center

    .wrapper
      overflow-x: auto
      -webkit-overflow-scrolling touch
      scroll-behavior: smooth
      &:hover > .actions
        opacity: 1
      &::-webkit-scrollbar-track
        height: 4px
        background #fff
      &::-webkit-scrollbar
        height: 4px
      &::-webkit-scrollbar-thumb
        background #eee
      &:hover::-webkit-scrollbar-thumb
        background #ccc
      .item
        display: inline-block
        position: relative
        margin-right: 20px
        padding-bottom: 10px
        z-index: 1
        &:hover > .poster .overlay
          opacity: 1
        .poster
          position: relative
          width: 220px
          height: 220px
          background-color: #eee
          background-image: url('/static_files/no-cover.jpg')
          background-size: 220px
          border-radius: 10px
          overflow: hidden
          border-radius: 10px
          img
            width: 100%
            min-height: 100%
            object-fit: cover
          .overlay
            opacity: 0
            position: absolute
            width: 100%
            height: 100%
            top: 0px
            left: 0px
            background-color: rgba(0, 0, 0, 0.35)
            transition: opacity 0.2s ease
            z-index: 1
            &.active
              opacity: 1
              cursor: pointer
            .pause
              position: absolute
              top: 50%
              left: 50%
              margin: -20px 0px 0px -20px
              width: 40px
              height: 40px
              background-image: url('/static_files/icons/pause.png')
              background-size: 40px
            .play
              position: absolute
              top: 50%
              left: 50%
              margin: -20px 0px 0px -20px
              width: 40px
              height: 40px
              background-image: url('/static_files/icons/play-2.png')
              background-size: 40px
            .start
              position: absolute
              padding: 16px
              left: 10px
              bottom: 10px
              background-image: url('/static_files/icons/play-2.png')
              background-size: 12px
              background-position: center
              background-repeat: no-repeat
              border-radius: 50%
              background-color: rgba(255, 255, 255, 0.35)
              backdrop-filter: blur(20px)
              -webkit-backdrop-filter: blur(20px)
              cursor: pointer
              &:hover
                background-color: #f9243b
        strong
          display: inline-block
          padding-top: 10px
          font-size: 1.2rem
          line-height: 1.5rem
          max-width: 220px
          cursor: pointer
          &:hover
            border-bottom: 1px solid
        // span
        //   display: block
        //   color: rgba(0, 0, 0, 0.5)
        //   font-size: 1.2rem
</style>
